.home-container {
    background: 
      linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)),
      url('https://img.freepik.com/free-photo/woman-wearing-box-head_23-2147758781.jpg') no-repeat center center/cover;
    min-height: 100vh; 
    margin-top: 55px;
    padding-top: 100px;
    padding-bottom: 100px;
  }
  
  
  .contact-form {
    background: rgba(255, 255, 255, 0.1); /* Semi-transparent white */
    border-radius: 10px;
    padding: 2rem;
    width: 100%;
    max-width: 600px;
    backdrop-filter: blur(10px); /* Apply blur to the background */
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3); /* Soft shadow to create depth */
    transition: transform 0.3s ease-in-out;
    opacity: 0.9;
  }
  
  .contact-form:hover {
    transform: translateY(-10px) scale(1.02); /* Adds a floating effect */
  }
/* On small screens, stack the elements vertically */
@media (max-width: 768px) {
    .home-container .d-flex {
      flex-direction: column;
    }
  
    .home-container .d-flex > div {
      margin-bottom: 20px; /* Add space between the elements */
    }
  }