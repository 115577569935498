.App {
  text-align: center;
  max-width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.img-container{
  position:relative;
  display:inline-block;
}
.img-container .overlay{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:rgb(0,0,0);
  opacity:0.8;
  transition:opacity 500ms ease-in-out;
}

.green-container {
  background: #B2D3C2;
}


@media (max-width: 768px) {
  /* On small screens, stack the image and text vertically */
  .d-flex {
    flex-direction: column;
    align-items: center;
  }

  .d-flex img, .d-flex div{
    margin-bottom: 20px;
  }
}

/* Reinigungsservice.css */
.responsive-img {
  width: 100%;          /* Full width on small screens */
  max-width: 300px;     /* Restrict width to 300px on larger screens */
  height: auto;         /* Maintain aspect ratio */
}
.responsive-map {
  max-width: 300px;
}


@media (min-width: 768px) {
  .responsive-img {
    max-width: 400px;   /* On medium screens and larger, increase width */
  }
  .responsive-map {
    max-width: 400px;
  }
}

@media (min-width: 1200px) {
  .responsive-img {
    max-width: 500px;   /* On large screens, allow for a wider image */
  }
  .responsive-map{
    max-width: 500px;
  }
}
